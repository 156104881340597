const translationRu = {
  flights: "Полёты",
  receive_packages: "Получите посылки",
  from_Turkey_and_from_China: "со всего мира",
  email_or_password_is_incorrect: "Неверный пароли или E-mail!",
  email: "Электронная почта",
  password: "Пароль",
  remember: "Сохранить",
  password_recovery: "Восстановление пароля",
  login: "Авторизация",
  register: "Регистрация",
  why_expressgroup: "Почему ExpressGroup?",
  our_advantages: "Наши преимущества",
  prices: "Цены",
  turkey: "Турция",
  china: "Китай",
  china_land: "Китай земля",
  usa: "США",
  bulk_shipments_are_subject_to_a_different_rate_based_on_actual_weights:
    "При больших объемах груза действует специальный (низкий) тариф, для получения подробной информации напишите нам.",
  what_ExpressGroup_offers: "Что предлогает ExpressGroup?",
  our_services: "Наши услуги",
  contact: "Контакт",
  write_us: "Напишите нам",
  send_us_an_email_via_mail: "Пришлите сообщение на электронную почту",
  call_us: "Позвоните нам",
  contact_us_only_during_working_hours: "Свяжитесь с нами в рабочее время",
  filials: "Филиалы",
  mon_fri: "Пн.-Пт.",
  saturdey: "Суббота",
  viev_on_the_map: "Посмотреть на карте",
  all_rights_reserved: "Все права защищены",
  terms_of_use_of_the_site: "Правила пользования сайтом",
  about_company: "О компании",
  priorities: "Преимущества",
  services: "Сервисы",
  language: "Язык",
  profile: "Профиль",
  parametrs: "Параметры",
  transactions: "Транзакции",
  dark_mode: "Темный режим",
  log_out: "Выход",
  ballance: "Баланс",
  payable: "Плата",
  parcels: "Посылки",
  faq: "Часто задаваемые вопросы",
  top_up: "Пополнение баланса",
  payment_by_card: "Оплата картой",
  service_fee_for_different_bank_cards:
    "Плата за обслуживание для различных банковских карт",
  pay: "Оплатить",
  enter_amount: "Введите сумму",
  banks1: "ТБС,Либерти,Халык банк,Базис банк,Тера банк",
  banks2: "Банк Грузии,Карту банк,Прокредит банк",
  room: "Личный кабинет",
  all: "Все",
  payment: "Оплата",
  general: "Общее",
  shops: "Магазины",
  addresses: "Адреса",
  for_woman: "Для женщин",
  for_man: "Для мужчин",
  for_childrens: "Для детей",
  self_care_products: "Средства для ухода",
  residence: "Бытовые предметы",
  brend_shops: "Брендовые магазины",
  add_new_address: "Добавить новый адрес",
  name: "Имя",
  surname: "Фамилия",
  address: "адрес",
  save: "Сохранить",
  flight: "Полет",
  arrived: "Прилетел",
  departure: "Прилетит",
  pay_all: "Полная оплата",
  from_china: "Из Китая",
  from_china_land: "Из Китая (земной)",
  from_turkey: "Из Турции",
  from_america: "Из США",
  parcels: "Посылки",
  in_the_warehouse: "На складе",
  sent: "Посланное",
  received: "Полученное",
  arrival_time: "Предположительное время прилёта",
  payed: "Оплачено",
  weight: "Вес",
  transportation: "Транспортировка",
  declaration: "Декларация",
  details: "Детали",
  tracking: "Трекинг",
  courier_service: "Курьерская доставка",
  products: "Продукты",
  sum: "Сумма",
  personal_parcel: "Личная посылка",
  web_site: "Веб-сайт",
  insurence: "Страховка",
  comment: "Комментарии",
  declarationn: "Декларация",
  add_new_product: "Добавка нового продукта",
  delete: "Удалить",
  add_invoice: "Добавить инвойс",
  price: "Цена",
  category: "Категория",
  add: "Добавить",
  "სხვადასხვა მზა ნაწარმი": "Разная готовая продукция",
  "ავტო ნაწილები": "Автозапчасти",
  "აუდიო აპარატურა": "Аудиоаппаратура",
  "აუდიო აპარატურის ნაწილი": "Детали аудиоаппаратуры",
  ბიჟუტერია: "Бижутерия",
  გასაღები: "Ключи",
  დანა: "Нож",
  "ვიდეო აპარატურა": "Видеоаппаратура",
  "ვიდეო აპარატურის ნაწილი": "Детали видеоаппаратуры",
  თეთრეული: "Постельное бельё",
  "თვითწებვადი ლენტი (სკოჩი)": "Самоклеющаяся лента(Скотч)",
  კომპიუტერი: "Компьютер",
  "კომპიუტერის ნაწილი": "Детали компьютера",
  "კოსმეტიკური საშუალებები": "Косметика",
  მედიკამენტები: "Медикоменты",
  "მინის ნაწარმი": "Стеклянные предметы",
  "მოტოციკლის ნაწილები": "Запчасти мотоцикла",
  "მუსიკალური ინსტრუმენტები": "Музыкальные инструменты",
  "მუსიკალური ინსტრუმენტის ნაწილი": "Детали музыкальных инструментов",
  მცენარეები: "Растения",
  "ნაბეჭდი პროდუქცია": "Печатная продукция",
  პარფიუმერია: "Парфюмерия",
  "პლასტმასის საკანცელარიო ნივთები": "Пластмассовые канцелярские предметы",
  "პლასტმასის სასადილო, სამზარეულო ჭურჭელი": "Пластмассовая кухонная посуда",
  "რეზინის ნაწარმი": "Резиновая продукция",
  საათი: "Часы",
  "საზომ-საკონტროლო ხელსაწყოები": "Контрольно-измерительные приборы",
  სათამაშო: "Игрушки",
  სათვალე: "Очки",
  საკეტი: "Замки",
  "საკვები დანამატები": "Пищевые добавки",
  "სანათი მოწყობილობები": "Осветительные приборы",
  "საოფისე ინვენტარი": "Офисный инвентарь",
  "სარეცხი და საწმენდი საშუალებები": "Чистящие и моющие средства",
  სასწორი: "Весы",
  "საყოფაცხოვრებო ავეჯი": "Мебель",
  "საყოფაცხოვრებო ტექნიკა": "Бытовая техника",
  "სპორტული ინვენტარი": "Спортивный инвентарь",
  ტანსაცმელი: "Одежда",
  ტელეფონი: "Телефон",
  ტენტი: "Тенты",
  "ფაიფურის ჭურჭელი": "Фарфоровая посуда",
  ფეხსაცმელი: "Обувь",
  "ფოტო აპარატურა": "Фотоаппаратура",
  "ფოტო აპარატურა": "Фотоаппаратура",
  "ქსელური მოწყობილობა": "Сетевые приборы",
  ჩანთა: "Сумка",
  "ხელის ინსტრუმენტები": "Ручные инструменты",
  "სხვადასხვა ელექტრონული მოწყობილობები": "Разные электронные приборы",
  invoice: "Инвойс",
  inv_name: "Название: ООО ..Экспресс Групп",
  inv_identification_code: "И/К: 405427364",
  inv_phone: "Телефон: 0 322 197 192",
  inv_email: "Электронная почта: info@expressgroup.ge",
  inv_address: "Адрес: ул. Газапхулис 4",
  recipient: "Получатель",
  fullname: "Имя Фамилия",
  id: "Л/Н",
  room_id: "Номер личного кабинета",
  phone: "Телефон",
  email: "Эл. Почта",
  tracking_number: "Номер посылки",
  exp_ltd: "ООО ,,Экспресс Групп",
  bank_details: "Банковские реквезиты",
  bank_code: "Код банка",
  inv_pr: "Во время оплаты укажите номер личного кабинета",
  profile: "Профиль",
  change_photo: "Поменять фото",
  name_ge: "Имя на грузинском",
  surname_ge: "Фамилия на грузинском",
  name_en: "Имя на латинском",
  surname_en: "Фамилия на латинском",
  birth_date: "Дата рождения",
  id_num: "Личный номер",
  gender: "Пол",
  city: "Город",
  man: "Мужчина",
  woman: "Женщина",
  transactions: "Транзакции",
  enroll: "Зачисление",
  successful: "Успешно",
  unsuccessful: "Не успешно",
  parametrs: "Параметры",
  manage_your_personal_information: "Управляй своей персональной информацией",
  security: "Безопасность",
  view_or_change_your_password: "Посмотри или поменяй свой пароль",
  my_addresses: "Мои адреса",
  manage_your_address_for_better_courier_service:
    "Управляй своим адресом для лучшей курьерской доставки",
  notification: "Уведомления",
  manage_the_information_you_want_to_receive_via_SMS_and_Email:
    "Управляй информацией, которую хочешь получить ввиде SMS или Email",
  passwords_don_match:
    "Старый пароль неверный или новый не совпадает с повторным",
  password_changed: "Пароль успешно изменён",
  to_keep_your_password_safe_please_observe_the_following_Recommendations:
    "Для того, чтобы пароль был надежным, следуй следующим рекомендациям",
  must_contain_at_least_characters: "Пароль должен содержать 6 символов",
  it_is_recommended_to_include_at_least_one_number:
    "Пароль должен содержать минимум одну цифру",
  it_is_recommended_to_include_at_least_one_different_characte:
    "Пароль должен содержать минимум один другой символ",
  do_not_use_simple_and_common_passwords:
    "Не используйте легкие или распространенные пароли",
  password: "Пароль",
  new_password: "Новый пароль",
  conf_password: "Повторите новый пароль",
  parcel_warehouse: "Получение посылки в склад",
  parsel_sent: "Посылка послана",
  parsel_arrived: "Посылка доставлена",
  parcel_received: "Посылка получена",
  registration: "Регистрация",
  step: "Этап",
  contact_info: "Контакт",
  personal_info: "Личная информация",
  user_type: "Тип пользователя",
  physical_person: "Физическое лицо",
  legal_entity: "Юридическое лицо",
  next: "Далее",
  back: "Назад",
  underage: "Несовершеннолетний!",
  incorrect_id: "Л.Н. не верный!",
  dir_name_ge: "Имя директора (Гр.)",
  dir_surname_ge: "Фамилия директора(Гр.)",
  dir_name_en: "Имя директора (Анг.)",
  dir_surname_en: "Фамилия директора(Анг.)",
  dir_id: "Личный номер директора",
  error: "Ошибка!",
  password_dont_match: "Пароль не совпадает!",
  invalid_email: "Эл.почта не верна!",
  i_agree_the_terms_and_conditions_of_the_site:
    "Соглашаюсь с правилами и условиями данного сайта",
  in_warehouse: "В складе",
  change_pass_email: "Поменять пароль эл.почтой",
  change_pass: "Поменять пароль",
  success: "Действие завершено успешно!",
  parcels_arrived_at_the_warehouse: "Посылки, прибывшие в склад",
  search: "Поиск",
  transportation: "Транспортировка",
  choose: "Выбери",
  bag: "Сумка",
  receive: "Получить",
  user_information: "Информация пользователя",
  parcel_information: "Информация о посылке",
  parcel_id: "ID Посылки",
  price_sum: "Задекларированная стоимость",
  date: "Дата",
  secured: "Застраховано",
  unsecured: "Незастраховано",
  send: "Отправить",
  company_name: "Название компании (En)",
  company_ic: "И/К компании",
  parcel: "Посылки",
  symbol: "9 Символов!",
  trancking_number_is_alreay_added: "Похожий Barcode уже добавлен",
  el_code_err:
    "Код указанный на эл.почте не совпадает или фиксируется пустое поле!",
  phone_code_err:
    "Код указанный в телефоне не совпадает или фиксируется пустое поле!",
  email_registerd:
    "Эл.почта уже зарегистрированная или фиксируется пустое поле!",
  phone_registerd:
    "Телефонный номер уже зарегистрирован или фиксируется пустое поле!",
  id_registerd: "Личный номер уже зарегистрирован или фиксируется пустое поле!",
  sc_registerd:
    "Идентификационный номер уже зарегистрирован или фиксируется пустое поле",
  does_not_match: "Код не совпадает или фиксируется ошибка другого типа!",
  recover_password: "Восстановить пароль",
  password_sent: "Пароль послан!",
  user_do_not_exists: "Пользователь не найден!",
  log_out: "Выход",
  declaration_successfull: "Посылка успешно задекларирована",
  add_new_address: "Добавить новый адрес",
  update_address: "Обновить адрес",
  update: "Обновление",
  email_code: "Код электронной почты",
  phone_code: "Код телефона",
  send_code: "Отправить код",
  confirm: "Подтвердить",
  product: "Производство",
  fill_all_fields: "Заполните все поля!",
  pay_successfull: "Платеж успешно завершен!",
  clearance_0: "это таможенное оформление",
  clearance_1: "Таможенное оформление",
  messeges: "Сообщения",
  codes: "коды",
  else: "другие сайты",
  dec_text:
    "P.S. Если общая стоимость товара или посылки на одном рейсе превышает 300 лари или вес превышает 30 кг, посылка подлежит таможенному оформлению, пожалуйста, загрузите счет-фактуру!",
  "მოცულობითი წონით": "по объемному весу",
  "რეალური წონით": "по Настоящий весу",
  invalid_phone: "Номер телефона недействителен!",
  fill_phone_required: "Укажите номер телефона!",
  please_fill_website_field: "Поле сайт обязательно!",
  check_currior_box:
    "Отметьте курьерскую службу, иначе изменения не будут зарегистрированы!",
  where_you_take_package: "Где вы хотите забрать посылку?",
  no_amount_was_specified: "Укажите желаемую сумму!",
  pay_transportation:
    "Пожалуйста, оплатите стоимость доставки перед подписанием курьера",
  dec_pack_err: "Пожалуйста, добавьте новый продукт",
  address_required:
    "Указание адреса обязательно, нужный адрес можно обновить/добавить: Настройки -> Мои адреса",
  setting_filil_text: "Укажите, из какого отделения вы хотите забрать посылку",
  chose_filial: "Выбрать ветку",
  choose_city: "Выберите город",
  pay_success: "Платеж успешно осуществлен",
  all_paid: "Неоплаченный пакет не найден",
  not_enough_money: "На счету недостаточно денег",
  length: "длина",
  width: "ширина",
  height: "высота",
  calculate: "расчет",
  calc_volume_weight: "рассчитать объемный вес",
  value_cant_0: "Значение не может быть 0",
  fields_empty: "Заполните все поля",
  volume_weight: "Вес тома",
  already_paid: "оплачено",
  courier_clearance: "Курьерское оформление",
  courier_description:
    "Отметьте нужные посылки, для которых вы хотите, чтобы курьерская служба подписала контракт",
  office_notification_text:
    "Укажите офис, из которого вы хотите забрать посылку",
  chose_filial_caution:
    "Смена филиала коснется только тех посылок, которые поступили на зарубежный склад после этого изменения",
  select_all: "Выбрать все",
  land: "земной",
  air: "авиа",
  instructions: "инструкции",
  video_instructions: "Видеоинструкции",
  prices_text: "Вес рассчитывается с точностью до 100 грамм",
  cm: "см",
  kg: "кг",
  greece: "Греция",
  curriorText: `Стоимость доставки посылки до 3 кг в Тбилиси составляет 5,50 лари.
    Стоимость доставки посылок от 3 до 10 кг – 10 лари. Стоимость доставки посылок до 5 кг по региону
    Это 7-12 лари. От 5 кг и выше действуют разные тарифы, в зависимости от конкретного адреса.`,
  curriorText2: `Сначала оплатите стоимость доставки посылки, которую должен оформить курьер`,
  foreigner: "гражданин чужой страны",
  cheque: "чек на 20 лари",
  yellow: "в желтом",
  yellowCautionText:
    "Ваша посылка(и) прибыла в желтый коридор. Пожалуйста, немедленно загрузите счета для каждой посылки",
  advertising: "Реклама",
  deleteAccount: "удалить аккаунт",
  autoParts: "Автозапчасти",
  other: "Другое",
  parcel_clarence: "Оформление посылок",
  reallyWantClarenceText: "Вы действительно хотите очистить предмет?!",
  clarence_caution_text:
    "Обратите внимание! Не отмечайте таможенное оформление, если общая стоимость ваших посылок за рейс не превышает 300 лари; не превышает 30 килограммов; Существует немало однородных вещей; Или вы не хотите оформлять посылки самостоятельно.",
  yes: "Да",
  no: "нет",
  england: "Англия",
  რეალური: "Реал",
  მოცულობითი: "Объемный",
  pending: "в ожидании",
  currior: "курьер",
  trusteeError: "Личный номер, дом и фамилия являются обязательными полями!",
  anotherPerson: "другой человек",
  incorrect_id: "Личный номер недействителен",
  already_updated: "вы уже обновили данные за последние 5 секунд",

  transport_price: "цена перевозки",
  for_legal_entity: "Для юридических лиц действуют специальные тарифы.",
  day: "день",
};

export default translationRu;
